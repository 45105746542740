<template>
  <div>
    <Form :model="formData" :label-width="100" :label-colon="true" inline>
      <FormItem>
        <span slot="label" class="validate">标题:</span>
        <Input
          v-model="formData.title"
          :maxlength="50"
          placeholder="请输入信息名称，不超过50字，20字以内最佳"
          style="width: 500px"
        ></Input>
      </FormItem>
      <FormItem>
        <span slot="label" class="validate">信息来源:</span>
        <Input
          v-model="formData.source"
          :maxlength="20"
          placeholder="请输入信息来源"
          style="width: 500px"
        ></Input>
      </FormItem>
      <FormItem>
        <span slot="label" class="validate">{{businessType == '1' ? '讨论时限:' : "公示时限:"}}</span>
        <DatePicker
          type="datetimerange"
          placeholder="请选择讨论开始时间和结束时间"
          v-model="formData.time"
          style="width: 500px"
        ></DatePicker>
      </FormItem>
      <FormItem style="width: 100%">
        <span slot="label" class="validate">{{businessType == '1' ? '讨论范围:' : "公示范围:"}}</span>
        <Cascader
            v-if="!hiddenCascader"
          style="width: 500px"
          :data="communityList"
          :load-data="loadCommunity"
          @on-change="getGroupTree"
          v-model="formData.dataScopeCode"
        ></Cascader>
        <Collapse v-model="callapseValue" style="width: 500px;margin-top: 20px" v-if="formData.dataScopeCode.length > 0"> 
        <Panel name="1">
            选择群范围
            <p slot="content">
              <span style="display: flex;justify-content: space-around">
                <Button type="primary" @click="allSelectGroup">全选</Button>
                <Button type="primary" @click="deleteSelectGroup">清除选择</Button>
                <Button type="primary" @click="expandSelectGroup">全展开</Button>
                <Button type="primary" @click="shrinkSelectGroup">全收缩</Button>
              </span>
              <Tree :data="groupTreeData" empty-text="暂无群范围" @on-check-change="oneSelectGroupTree" show-checkbox multiple></Tree>
            </p>
        </Panel>
        </Collapse>
      </FormItem>
      <FormItem v-if="businessType == '3'">
          <Checkbox v-model="formData.chatDiscuss" border>可评论留言</Checkbox>
      </FormItem>
       <!-- <iframe
          name="ueditor"
          id="ueditor"
          src="/ueditor/index.html?titlename=议题详细描述（必填）"
          style="width: 100%; height: 500px; border: 0px"
        ></iframe> -->
        <iframe
                name="ueditor"
                id="ueditor"
                src="/mobileeditor/#/index?titlename=议题详细描述（必填）"
                style="width: 100%; height: 500px; border: 0px"
              ></iframe>
    </Form>
  </div>
</template>

<script>
export default {
  props: ['infoId',"businessType"],
  data() {
    return {
      formData: {
        source: parent.vue.loginInfo.userinfo.orgName,
        regionIds: [],
        dataScopeCode: []
      },
      //   讨论范围
      communityList: [],
      hiddenCascader: false,  //隐藏级联
      callapseValue: [],  //展开收缩群范围
      groupTreeData: [],  //群范围

      // 保存校验
      validateArr: [
        {
          desc: "请输入标题",
          key: "title",
        },
        {
          desc: "请输入信息来源",
          key: "source",
        },
        {
          desc: "请输入讨论时限",
          key: "time",
        },
        {
          desc: "请选择讨论时限",
          key: "time",
        },
        {
          desc: "请选择讨论范围",
          key: "dataScopeCode",
        },
        {
          desc: "请选择群范围",
          key: "regionIds",
        },
        {
          desc: "请输入议题详细描述",
          key: "content",
        },
      ]
    };
  },
  methods: {
    validateFn() {
      var v_Obj = document.getElementById("ueditor").contentWindow; //获取iframe对象
      this.formData.content = v_Obj.getContent(); //获取富文本内容
      for(let k in this.validateArr) {
        if(this.validateArr[k].key == 'time' && (this.formData[this.validateArr[k].key][0] == '' || this.formData[this.validateArr[k].key][1] == '')) {
          this.$Message.error({
            background: true,
            content: this.validateArr[k].desc
          })
          return;
        }else if(this.formData[this.validateArr[k].key] === undefined || this.formData[this.validateArr[k].key] === null || this.formData[this.validateArr[k].key] === '' || this.formData[this.validateArr[k].key].length == 0) {
          this.$Message.error({
            background: true,
            content: this.validateArr[k].desc
          })
          return;
        }
      }
      this.save();
    },
    save() {
      let url = '';
      if(this.businessType == '1') {
        url = '/sendmsg/pc/chat/discussBusiness/addDiscussBusiness';
      }else if(this.businessType == '3') {
        url = '/sendmsg/pc/chat/discussBusiness/addNoticeBusiness'
      }
        this.$post(url,{
          businessType: this.businessType,
          ...this.formData,
          custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
          startTime: this.$core.formatDate(new Date(this.formData.time[0]),"yyyy-MM-dd hh:mm:ss"),
          endTime: this.$core.formatDate(new Date(this.formData.time[1]),"yyyy-MM-dd hh:mm:ss"),
          modifier: parent.vue.loginInfo.userinfo.realName,
          oemCode: parent.vue.oemInfo.oemCode,
          orgCode: parent.vue.loginInfo.userinfo.orgCode,
          regionIds: this.formData.regionIds.join(','),
          dataScopeCode: this.formData.dataScopeCode[this.formData.dataScopeCode.length -1].split('-')[this.formData.dataScopeCode[this.formData.dataScopeCode.length -1].split('-').length -1],
          time: null, 
          chatDiscuss: this.formData.chatDiscuss && this.businessType == '3' ? 'ON' : !this.formData.chatDiscuss && this.businessType == '3' ?'OFF' : '',
        }).then(res => {
          if(res.code == 200) {
            this.$Message.success({
              background: true,
              content: "保存成功"
            })
            this.$emit('saveSuccess');
          }else {
            this.$Message.error({
              background: true,
              content: res.desc
            })
          }
        }).catch(err => {
          console.log(err);
          this.$Message.error({
            background: true,
            content: "保存失败，请联系管理员处理"
          })
        })
    },
    // 动态加载街道/社区
    loadCommunity(item, callback) {
      item.loading = true;
      let arrCode = item.value.split("-");
      this.$get("/old/api/pc/staff/selectCascadeDataScope", {
        orgCode: arrCode[arrCode.length - 1],
        oemCode: parent.vue.oemInfo.oemCode,
        orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
      }).then((res) => {
        if (res.code == 200) {
          if (!item.index) {
            if (res.dataList.length == 1) {
              //如果只有一项，默认选中
              this.formData.dataScopeCode.push(item.orgPath);
            }
            item.children = res.dataList.map((item) => {
              //街道
              return {
                value: item.orgPath,
                label: item.orgName,
                orgLevel: item.orgLevel,
                loading: false,
                children: [],
                index: 1,
              };
            });
          } else {
            if (res.dataList.length == 1) {
              //如果只有一项，默认选中,并且隐藏掉级联
              this.formData.dataScopeCode.push(item.orgPath);
              this.hiddenCascader = true;
            }else {
                this.hiddenCascader = false;
            }
            item.children = res.dataList.map((item) => {
              //社区
              return {
                value: item.orgPath,
                label: item.orgName,
                orgLevel: item.orgLevel,
              };
            });
          }
          item.loading = false;
          callback();
        }
      });
    },
    // 获取区
    getPulishData(code) {
      this.$get("/old/api/pc/staff/selectCascadeDataScope", {
        orgCode: code,
        oemCode: parent.vue.oemInfo.oemCode,
        orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
      }).then((res) => {
        if (res.code == 200) {
          if (res.dataList.length == 1) {
            //如果只有一项，默认选中
            this.formData.dataScopeCode.push(item.orgPath);
          }
          this.communityList = res.dataList.map((item) => {
            return {
              value: item.orgPath,
              label: item.orgName,
              orgLevel: item.orgLevel,
              loading: false,
              children: [],
            };
          });
        }
      });
    },
    // 获取群范围tree
    getGroupTree(code) {
      let orgCode = code[code.length -1].split('-')[code[code.length -1].split('-').length -1];
      this.$get('/sendmsg/pc/chat/discussBusiness/getRegionTree',{
        businessId: this.infoId,
        oemCode: parent.vue.oemInfo.oemCode,
        orgCode: orgCode
      }).then(res => {
        if(res.code == 200 && res.dataList) {
          this.groupTreeData = res.dataList;
        }else if(res.code == 200 && !res.dataList) {
          this.$Message.error({
            background: true,
            content: "未查询到您的群范围"
          })
        }else {
          this.$Message.error({
            background: true,
            content: res.desc
          })
        }
      })
    },
    // 单次选中群范围
  oneSelectGroupTree(arr) {
    this.formData.regionIds = [];
    this.selectGroupTree(arr);
  },
    // 全选，单次选中时，push到regionIds
    selectGroupTree(arr) {
      arr.map(item => {
        if(!item.children || item.children.length == 0) {
          this.formData.regionIds.push(item.id);
          this.formData.regionIds = Array.from(new Set(this.formData.regionIds));
        }else {
          this.selectGroupTree(item.children);
        }
      });
    },
    // 封装全选和反选群范围
    allDeleteSelectGroup(tree,checkVal,expandVal) {
      const result = [];
    tree.forEach((item) => {
      let id = item.id;
      let parentId = item.parentId;
      let expand = expandVal !== '' ? expandVal : item.expand;
      let checked = checkVal !== '' ? checkVal : item.checked;
      let title = item.title;
      let code = item.code;
      let children = item.children;
      // 如果有子节点，递归
      if (children && children.length != 0) {
        children = this.allDeleteSelectGroup(children, checkVal,expandVal);
      }
      result.push({ expand, code, checked, title, children,id,parentId });
    })
    return result;
    },
    // 全选群范围
    allSelectGroup() {
     this.formData.regionIds = [];
     this.groupTreeData = this.allDeleteSelectGroup(this.groupTreeData,true,'');
     this.selectGroupTree(this.groupTreeData);
    },
    // 反选群范围
    deleteSelectGroup() {
      this.groupTreeData = this.allDeleteSelectGroup(this.groupTreeData,false,'');
      this.formData.regionIds = [];
    },
    expandSelectGroup() {
      this.groupTreeData = this.allDeleteSelectGroup(this.groupTreeData,"",true);
    },
    shrinkSelectGroup() {
      this.groupTreeData = this.allDeleteSelectGroup(this.groupTreeData,"",false);
    },
  },
  created() {
    sessionStorage.setItem('NODEENV',process.env.NODE_ENV)
    // 获取广州市的区
    this.getPulishData(4401);
  },
};
</script>
    
<style scoped lang='less'>
/deep/.validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}
</style>